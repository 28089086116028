
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBRNf848ISL8Iu-hHBNd9lpu5LxeWAWW_I",
  authDomain: "merho-af94b.firebaseapp.com",
  projectId: "merho-af94b",
  storageBucket: "merho-af94b.appspot.com",
  messagingSenderId: "728851481445",
  appId: "1:728851481445:web:c54d2a018847feccdb978c"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();