import React, { useEffect, useState } from "react";
import "./news.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  TextField,
  Button,
  Grid,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { addDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from "./firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { deleteDoc } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const News = () => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [news, setNews] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "news"));
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list.sort((a, b) => parseFloat(a.timeStamp) - parseFloat(b.timeStamp));
        setNews(list);
        console.log(list);
      },
      (error) => {
        console.log(error);
      },
      []
    );

    return () => {
      unsub();
    };
  }, []);

  const style = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "white",
    borderShadow: "2%",
    borderRadius: "25px",
    padding: "4%",
  };
  const style_1 = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    borderShadow: "2%",
    borderRadius: "25px",
  };

  const navigate = useNavigate();

  const handleDetail = (row) => {
    navigate("/detail", { state: { ...row } });
  };

  const handleDeleted = async () => {
    const deleteRef = doc(db, "news", id);
    await deleteDoc(deleteRef);
    handleClose_1();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (row) => {
    setNews(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [id, setId] = useState("");
  const [open_1, setOpen_1] = useState(false);

  const handleOpen_1 = (row) => {
    console.log("open");
    setId(row.id);
    setOpen_1(true);
  };

  const handleClose_1 = () => setOpen_1(false);

  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [file, setFile] = useState("");

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setNewsData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const handleUpdate = async () => {
    const updateRef = doc(db, "news", newsData.id);
    await updateDoc(updateRef, {
      ...newsData,
      lastUpdated: serverTimestamp(),
    });
    handleClose();
  };

  const [newsData, setNewsData] = useState({
    title: "",
    snippet: "",
    content: "",
    img: "",
  });

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "link"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        [{ align: [] }],
        ["clean"],
        [{ color: [] }],
      ],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const handleChange = (value) => {
    setNewsData({ ...newsData, content: value });
  };

  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#4d7386",
    size: "small",
  }));

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const handleSuccess = () => {
    setOpenSuccess(true);
  };

  const handleError = () => {
    setOpenError(true);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };
  return (
    <div>
      <section className="section news" aria-labelledby="news-label">
        <div className="container">
          <p className="section-subtitle " id="news-label">
            News
          </p>

          <h2 className="h2 section-title ">Latest Articles</h2>

          <ul className="grid-list">
            {news.map((n) => {
              return (
                <li key={n.id}>
                  <div className="news-card">
                    <figure className="card-banner img-holder">
                      <img
                        src={n.img}
                        width="560"
                        height="350"
                        loading="lazy"
                        alt="Ligula tristique quis risus"
                        className="img-cover"
                      />
                    </figure>

                    <div className="card-content">
                    
                          <h3 className="h3">
                            <a href="#" className="card-title">
                              {n.title}
                            </a>
                          </h3>
                    
                      <p className="card-text">{n.snippet}</p>

                      <ul className="card-meta-list">
                        <li>
                          <button
                            style={{
                              backgroundColor: "#4d7386",
                              color: "white",
                              padding: "15% 60%",
                            }}
                            onClick={(e) => handleDetail(n)}
                          >
                            Detail
                          </button>
                        </li>
                        <li className="card-meta-item">
                          <FontAwesomeIcon
                            icon={faEdit}
                            color="#4d7386"
                            size="lg"
                            style={{ margin: "4%" }}
                            onClick={(e) => {
                              setNewsData(n);
                              setOpen(true);
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faTrash}
                            color="#4d7386"
                            size="lg"
                            style={{ margin: "4%" }}
                            onClick={(e) => handleOpen_1(n)}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style_1, width: "50%" }}>
          <center>
            <div
              style={{
                backgroundColor: "#4d7386",
                color: "#E4EDED",
                borderTopLeftRadius: "25px",
                borderTopRightRadius: "25px",
              }}
            >
              <center>
                <Typography variant="h6" sx={{ p: 3 }}>
                  Update News
                </Typography>
              </center>
            </div>

            <div>
              <Grid
                container
                spacing={2}
                style={{
                  overflowY: "scroll",
                  height: "50vh",
                  marginTop: "4%",
                  padding: "4%",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ float: "left" }}
                >
                  <div className="bottom">
                    <div className="left">
                      <img src={newsData.img} alt="" />
                    </div>
                  </div>
                  <div className="formInput">
                    <label htmlFor="file">
                      Image: <DriveFolderUploadOutlinedIcon className="icon" />
                    </label>
                    <input
                      type="file"
                      id="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      style={{ display: "none" }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ float: "left" }}
                >
                  <TextField
                    label="Title"
                    id="outlined-size-small"
                    size="small"
                    value={newsData.title}
                    onChange={(e) =>
                      setNewsData({ ...newsData, title: e.target.value })
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ float: "left" }}
                >
                  <textarea
                    label="Snippet"
                    id="outlined-size-small"
                    size="small"
                    value={newsData.snippet}
                    onChange={(e) =>
                      setNewsData({ ...newsData, snippet: e.target.value })
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ float: "left", width: "100%" }}
                >
                  <center>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      value={newsData.content}
                      formats={formats}
                      onChange={handleChange}
                    />
                  </center>
                </Grid>

                <StyledButton
                  variant="contained"
                  onClick={handleUpdate}
                  sx={{ m: "5% auto" }}
                >
                  Save
                </StyledButton>
                <center></center>
              </Grid>
              <Snackbar
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleCloseSuccess}
              >
                <Alert
                  onClose={handleCloseSuccess}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Data Added Successfully!
                </Alert>
              </Snackbar>
              <Snackbar
                open={openError}
                autoHideDuration={6000}
                onClose={handleCloseError}
              >
                <Alert
                  onClose={handleCloseError}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Data Added Successfully!
                </Alert>
              </Snackbar>
            </div>
          </center>
        </Box>
      </Modal>
      <Modal
        open={open_1}
        onClose={handleClose_1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure you want to delete this!
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} sm={6} lg={6}>
              <button
                onClick={handleDeleted}
                style={{
                  float: "right",
                  width: "15vw",
                  margin: "5%",
                  border: "none",
                  borderRadius: "25px",
                  backgroundColor: "#4d7386",
                  color: " #E4EDED",
                }}
              >
                Yes
              </button>
            </Grid>
            <Grid item xs={6} md={6} sm={6} lg={6}>
              <button
                onClick={handleClose_1}
                style={{
                  float: "left",
                  width: "15vw",
                  margin: "5%",
                  border: "none",
                  borderRadius: "25px",
                  backgroundColor: "#4d7386",
                  color: " #E4EDED",
                }}
              >
                No
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default News;
