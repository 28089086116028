import { Route, Routes } from "react-router-dom";
import AddNews from "./AddNews";
import AddUser from "./AddUser";
import "./App.css";
import Header from "./Header";
import Login from "./Login";
import News from "./News";
import User from "./User";
import MoreNews from "./MoreNews";
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

function App() {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/" />;
  };

  const [show, setShow] = useState(true);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname == "/") {
      setShow(false);
    }
  }, []);

  return (
    <div className="App">
      {show && <Header />}
      <Routes>
        <Route basename={<Login />} path="/" element={<Login />} />
        <Route
          path="/news"
          element={
            <RequireAuth>
              <News />
            </RequireAuth>
          }
        />
        <Route
          path="/addnews"
          element={
            <RequireAuth>
              <AddNews />
            </RequireAuth>
          }
        />
        <Route
          path="/adduser"
          element={
            <RequireAuth>
              <AddUser />
            </RequireAuth>
          }
        />
        <Route
          path="/user"
          element={
            <RequireAuth>
              <User />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/detail"
          element={
            <RequireAuth>
              <MoreNews />
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
