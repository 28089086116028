import {
  Alert,
  Button,
  Snackbar,
  TextField,
  TextareaAutosize,
} from "@mui/material";

import "./add_news.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { addDoc, serverTimestamp, collection } from "firebase/firestore";
import { db, storage } from "./firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";

const AddNews = () => {
  const [file, setFile] = useState("");
  const [per, setPerc] = useState(null);

  const [news, setNews] = useState({
    title: "",
    snippet: "",
    content: "",
    date: Date.now()
  });

  const handleClear = () => {
    news.title = "";
    news.content = "";
    news.snippet = "";
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "link"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        [{ align: [] }],
        ["clean"],
        [{ color: [] }],
      ],
    },
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const handleChange = (value) => {
    setNews({ ...news, content: value });
  };

  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "news"), {
        ...news,
        timeStamp: serverTimestamp(),
      });
      handleSuccess();
    } catch (error) {
      console.log("error :" + error.serverResponse);
      handleError();
    }
    handleClear();
  };

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setNews((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
    console.log("img");
  }, [file]);

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const handleSuccess = () => {
    setOpenSuccess(true);
  };

  const handleError = () => {
    setOpenError(true);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  return (
    <div className="row">
      <form action="" method="post">
        <div>
          <h2>Add Articles</h2>
        </div>

        <div>
          <div>
            <center>
              <img
                style={{ width: "15%" }}
                src={
                  file
                    ? URL.createObjectURL(file)
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt=""
              />
            </center>
          </div>
          <div style={{ margin: "2% auto", justifyContent: "center" }}>
            <label htmlFor="file">
              Image: <DriveFolderUploadOutlinedIcon className="icon" />
            </label>
            <input
              type="file"
              id="file"
              onChange={(e) => setFile(e.target.files[0])}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div>
          <TextField
            label="Title"
            className="textfield"
            onChange={(e) => setNews({ ...news, title: e.target.value })}
          />
        </div>
        <div>
          <textarea
            placeholder="Snippet"
            id="outlined-size-small"
            size="small"
            rows={2}
            style={{
              border: "1px solid grey",
              width: "60vw",
              height: "10vh",
              padding: "2%",
              marginBottom: "3%",
            }}
            value={news.snippet}
            onChange={(e) => setNews({ ...news, snippet: e.target.value })}
          />
        </div>
        <div>
          <center>
            <ReactQuill
              theme="snow"
              modules={modules}
              value={news.content}
              formats={formats}
              onChange={handleChange}
            />
          </center>
        </div>
        <div className="row">
          <Button onClick={handleSubmit}>Save</Button>
          <Button onClick={handleClear}>Cancel</Button>
        </div>
      </form>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Data Added Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="success"
          sx={{ width: "100%" }}
        >
          {message}!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddNews;
