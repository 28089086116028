import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "./firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Grid, Modal, Snackbar, TextField } from "@mui/material";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteDoc } from "firebase/firestore";
import styled from "@emotion/styled";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "first_name",
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "last_name",
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email",
  },
  {
    id: "password",
    disablePadding: false,
    label: "Password",
  },
  {
    id: "update",
    disablePadding: false,
    label: "Update",
  },
  {
    id: "delete",
    disablePadding: false,
    label: "Delete",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Users
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function User() {
  

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "user"));
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list.sort((a, b) => parseFloat(a.timeStamp) - parseFloat(b.timeStamp));
        setUsers(list);
        console.log(list);
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const style = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "white",
    borderShadow: "2%",
    borderRadius: "25px",
    padding: "4%",
  };
  const style_1 = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    borderShadow: "2%",
    borderRadius: "25px",
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (row) => {
    setUsers(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleted = async () => {
    const deleteRef = doc(db, "user", id);
    await deleteDoc(deleteRef);
    handleClose_1();
  };

  const [id, setId] = useState("");
  const [open_1, setOpen_1] = useState(false);

  const handleOpen_1 = (row) => {
    console.log("open");
    setId(row.id);
    setOpen_1(true);
  };

  const handleClose_1 = () => setOpen_1(false);

  const handleUpdate = async () => {
    const updateRef = doc(db, "user", newsData.id);
    await updateDoc(updateRef, {
      ...newsData,
      lastUpdated: serverTimestamp(),
    });
    handleClose();
  };

  const [newsData, setUsersData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const handleChange = (value) => {
    setUsersData({ ...newsData, content: value });
  };

  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#4d7386",
    size: "small",
  }));

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const handleSuccess = () => {
    setOpenSuccess(true);
  };

  const handleError = () => {
    setOpenError(true);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(users, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <p className="section-subtitle " id="news-label">
        Tursted Editors
      </p>

      <h2 className="h2 section-title ">Our Users</h2>
      <Paper style={{ margin: "auto", width: "60vw" }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <center>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={users.length}
              />
              <TableBody>
                {users.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>{row.first_name}</TableCell>
                      <TableCell>{row.last_name}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.password}</TableCell>
                      <TableCell>
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="#4d7386"
                          size="lg"
                          onClick={(e) => {
                            setUsersData(row);
                            setOpen(true);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <FontAwesomeIcon
                          icon={faTrash}
                          color="#4d7386"
                          size="lg"
                          onClick={(e) => handleOpen_1(row)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </center>
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={{ ...style_1, width: "50%" }}>
            <center>
              <div
                style={{
                  backgroundColor: "#4d7386",
                  color: " #E4EDED",
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                }}
              >
                <center>
                  <Typography variant="h6" sx={{ p: 3 }}>
                    Update User
                  </Typography>
                </center>
              </div>

              <div>
                <Grid
                  container
                  spacing={2}
                  style={{
                    overflowY: "scroll",
                    height: "50vh",
                    marginTop: "4%",
                    padding: "4%",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ float: "left" }}
                  >
                    <TextField
                      label="First Name"
                      id="outlined-size-small"
                      size="small"
                      value={newsData.first_name}
                      onChange={(e) =>
                        setUsersData({
                          ...newsData,
                          first_name: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ float: "left" }}
                  >
                    <TextField
                      label="Last Name"
                      id="outlined-size-small"
                      size="small"
                      value={newsData.last_name}
                      onChange={(e) =>
                        setUsersData({ ...newsData, last_name: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ float: "left" }}
                  >
                    <TextField
                      label="Email"
                      id="outlined-size-small"
                      size="small"
                      value={newsData.email}
                      onChange={(e) =>
                        setUsersData({ ...newsData, email: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ float: "left" }}
                  >
                    <TextField
                      label="Password"
                      id="outlined-size-small"
                      size="small"
                      value={newsData.password}
                      onChange={(e) =>
                        setUsersData({ ...newsData, password: e.target.value })
                      }
                    />
                  </Grid>

                  <StyledButton
                    variant="contained"
                    onClick={handleUpdate}
                    sx={{ m: "5% auto" }}
                  >
                    Save
                  </StyledButton>
                  <center></center>
                </Grid>
                <Snackbar
                  open={openSuccess}
                  autoHideDuration={6000}
                  onClose={handleCloseSuccess}
                >
                  <Alert
                    onClose={handleCloseSuccess}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Data Added Successfully!
                  </Alert>
                </Snackbar>
                <Snackbar
                  open={openError}
                  autoHideDuration={6000}
                  onClose={handleCloseError}
                >
                  <Alert
                    onClose={handleCloseError}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Data Added Successfully!
                  </Alert>
                </Snackbar>
              </div>
            </center>
          </Box>
        </Modal>
      </div>
      <Modal
        open={open_1}
        onClose={handleClose_1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure you want to delete this!
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} sm={6} lg={6}>
              <button
                onClick={handleDeleted}
                style={{
                  float: "right",
                  width: "15vw",
                  margin: "5%",
                  border: "none",
                  borderRadius: "25px",
                  backgroundColor: "#4d7386",
                  color: " #E4EDED",
                }}
              >
                Yes
              </button>
            </Grid>
            <Grid item xs={6} md={6} sm={6} lg={6}>
              <button
                onClick={handleClose_1}
                style={{
                  float: "left",
                  width: "15vw",
                  margin: "5%",
                  border: "none",
                  borderRadius: "25px",
                  backgroundColor: "#4d7386",
                  color: " #E4EDED",
                }}
              >
                No
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}
