import { Alert, Button, Snackbar, TextField, TextareaAutosize } from '@mui/material';
import './add_user.css';
import React,{useState, useEffect} from 'react';
import { addDoc, serverTimestamp, collection } from 'firebase/firestore';
import { db , storage} from './firebase';
import {  createUserWithEmailAndPassword, getAuth } from 'firebase/auth';



const AddUser = () => {

    const [user, setUser] = useState({
        first_name:'',
        last_name:'',
        email:'',
        password:'',
      });
    
      const handleClear = () => {
        user.first_name = '';
        user.email='';
        user.last_name='';
        user.password='';
    }


    const create_authentication = () => {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, user.email, user.password)
          .then((userCredential) => {
            const user = userCredential.user;
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            handleError();
          });
      };

    const [message, setMessage] = useState('');

    const handleSubmit = async(e) =>{
        e.preventDefault();
        user.email = user.email.trim();
        user.password = user.password.trim();
        
        createUserWithEmailAndPassword(user);

        try{
          await addDoc(collection(db, 'user'),{
            ...user,
            timeStamp: serverTimestamp()
          });
          handleSuccess();
        }catch(error){
          console.log('error :' + error.serverResponse);
          handleError();
        }
        handleClear();
      }



      const [openSuccess, setOpenSuccess] = React.useState(false);
   const [openError, setOpenError] = React.useState(false);
  
   const handleSuccess = () => {
     setOpenSuccess(true);
   };
  
   const handleError = () => {
     setOpenError(true);
   };
  
   const handleCloseSuccess = (event, reason) => {
     if (reason === 'clickaway') {
       return;
     }
  
     setOpenSuccess(false);
   };
  
   const handleCloseError = (event, reason) => {
     if (reason === 'clickaway') {
       return;
     }
  
     setOpenError(false);
   };


  return (
    <div className='row'>
        <form action="" method="post">
            <div>
                <h2>Add Users</h2>
            </div>
            <div>
                <TextField label='First Name' type='text' className='textfield' onChange={(e) => setUser({...user, first_name: e.target.value})}/>
                <TextField label='Last Name' type='text' className='textfield' onChange={(e) => setUser({...user, last_name: e.target.value})}/>
            </div>
            <div>
                <TextField type='email' label='Email' className='textfield' onChange={(e) => setUser({...user, email: e.target.value})}/>
                <TextField type='password' label='Password' className='textfield' onChange={(e) => setUser({...user, password: e.target.value})}/>
            </div>
            <div className='row'>
                <Button onClick={handleSubmit}>Save</Button>
                <Button onClick={handleClear}>Cancel</Button>
            </div>
        </form>
        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
           Data Added Successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }}>
           {message}!
        </Alert>
      </Snackbar>
    </div>
  )
}

export default AddUser