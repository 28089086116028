import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { TextField, CardHeader, Avatar, IconButton } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { AuthContext } from "./AuthContext.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const { dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();
    console.log("email: " + email + " password: " + password);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("user " + { ...user });
        dispatch({ type: "LOGIN", payload: user });
        navigate("/news");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.message);
        setError(true);
      });
    setEmail("");
    setPassword("");
  };

  return (
    <div className="login">
      <Card variant="outlined" className="content col">
        <CardHeader title="Login" subheader="Enter Email and Password" />
        <CardContent className="input-content col">
          <TextField
            className="input"
            label="Email"
            onChange={(e) => setEmail(e.target.value.trim())}
          />
          <TextField
            className="input"
            label="Password"
            onChange={(e) => setPassword(e.target.value.trim())}
          />
        </CardContent>
        <CardActions className="action-content">
          <Button
            variant="text"
            //   color="primary"
            size="small"
            className="button"
            onClick={handleLogin}
          >
            Login
          </Button>
          <br />
          {error && <span>Wrong email or password!</span>}
        </CardActions>
      </Card>
    </div>
  );
};

export default Login;
